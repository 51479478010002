type locationsType = {
  title: string,
  description: string
}

const photoSessions = ['СВАДЬБЫ', 'СЕМЬЯ', 'БИЗНЕС', 'БЛОГЕРЫ', 'ТУРИСТЫ'];

const photoZones = ['ВАННАЯ', 'NEWBORN', 'LOVE STORY', 'ДЕТСКАЯ ЗОНА', 'ЗОНА ДЛЯ ПОДКАСТОВ'];

const locations: locationsType[] = [
  {
    title: "Апельсиновые и гранатовые сады",
    description: "Яркие и насыщенные осенние пейзажи с сочными деревьями создают идеальный фон для портретов."
  },
  {
    title: "Цветущие яблони и лавандовые поля",
    description: "Весенний фоторул в цветущем саду для создания романтических и семейных фото в воздушной атмосфере."
  },
  {
    title: "Озеро Салда",
    description: "Белоснежные берега и бирюзовая вода озера напоминают экзотические пляжи, создавая уникальные кадры."
  },
  {
    title: "Море на скалах",
    description: "Съёмки на фоне морских скал и захватывающих видов для динамичных и атмосферных фотографий."
  },
  {
    title: "Древний Олимпос",
    description: "Погружение в историю среди древних руин, идеально для любителей античных сюжетов."
  },
  {
    title: "Гора Химера",
    description: "Ночные съёмки на фоне горы с вечным огнём для магических и экзотических кадров."
  },
  {
    title: "Ликийская тропа",
    description: "Пейзажи гор и побережья Ликийской тропы, отличная комбинация для пейзажных и портретных съёмок."
  },
  {
    title: "Долина бабочек",
    description: "Живописное место с водопадами и зелёными ущельями, идеально для природных фотосессий."
  },
  {
    title: "Водопад Куршунлу",
    description: "Съёмки у знаменитого водопада с каскадами воды и живописной природой."
  },
  {
    title: "Античный амфитеатр",
    description: "Съёмки среди руин амфитеатра с величественными архитектурными видами."
  },
  {
    title: "The Land of Legends",
    description: "Съёмки в известном развлекательном парке с яркими фонтанами и роскошной атмосферой."
  },
  {
    title: "Роскошные отели",
    description: "Элегантные съёмки в отелях Антальи и Алании, создающие стильные и роскошные образы."
  },
  {
    title: "Аланья",
    description: "Съёмки на пляжах и в исторических местах Алании с видами на море и горы."
  },
  {
    title: "Памуккале",
    description: "Съёмки на белоснежных террасах природного чуда Памуккале."
  },
  {
    title: "Яхта",
    description: "Живописные съёмки на фоне лазурной воды, сочетающей роскошь и романтику."
  },
  {
    title: "Каньоны рядом с Антальей",
    description: "Насыщенные и динамичные кадры для создания уникальных природных фотографий."
  },
  {
    title: "Рыбалка на яхте",
    description: "Съёмки во время рыбалки на яхте для любителей спокойных и романтичных образов."
  },
  {
    title: "Маршруты по выбору клиента",
    description: "Индивидуальные фотосессии на выбранных вами маршрутах и локациях."
  }
];

const photoTours: locationsType[] = [
  {
    title: "Стамбул",
    description: "Прогулка по историческим местам и крышам с видами на Галатскую башню и Босфор."
  },
  {
    title: "Айвалык",
    description: "Белоснежные улочки и яркие двери создадут атмосферу средиземноморского спокойствия."
  },
  {
    title: "Калкан",
    description: "Съёмки в старом городе, бухте Капуташ и пляжах Патара для исторических и природных кадров."
  },
  {
    title: "Ризе",
    description: "Чайные плантации и горные пейзажи для тех, кто хочет запечатлеть величие природы."
  },
  {
    title: "Каппадокия",
    description: "Волшебная атмосфера с воздушными шарами и лошадьми, создающими незабываемые кадры."
  }
];

export { photoSessions, photoZones, locations, photoTours };  
export type { locationsType };

