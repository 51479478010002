import { infoItem } from "../../utils/types/infoItem";

export const infoItems: infoItem[] = [
  {
    imgUrl: "images/info-block-img/1.svg",
    title: "Индивидуальный подход",
    description: "Каждая фотосессия — это не просто снимки, а уникальные воспоминания. Мы разрабатываем концепции, отражающие личность и настроение клиента, обеспечивая атмосферу доверия и комфорта на съёмке",
  },
  {
    imgUrl: "images/info-block-img/2.svg",
    title: "Качество",
    subTitle:"Профессионализм",
    description: "Мы используем новейшие камеры и дроны, создавая детализированные фотографии и видео. Наши кадры безупречны как в цифровом формате, так и на печати.",
  },
  {
    imgUrl: "images/info-block-img/3.svg",
    title: "Творческий подход",
    description: "Мы внимательно слушаем ваши пожелания и предлагаем свежие идеи, вдохновлённые искусством и природой. Каждая съёмка — это сочетание профессионализма и креативности.",
  },
  {
    imgUrl: "images/info-block-img/4.svg",
    title: "Внимание к деталям",
    description: "Мы не просто снимаем, мы вкладываем душу в каждую съёмку. Благодаря нашему профессионализму и мастерству, ваши идеи превращаются в настоящие произведения искусства.",
  },
  {
    imgUrl: "images/info-block-img/5.svg",
    title: "Уникальные локации",
    description: "Мы предлагаем съёмки на разнообразных локациях — от живописных природных пейзажей до исторических мест. Готовы отправиться на любые выбранные вами маршруты для создания максимально личных кадров",
  },
];